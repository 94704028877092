export const meiDataTypes = {
  "MEI 5.0": {
    apiIds: ["mei50", "c-mei-all"],
    hash: "",
    link: "",
    format: "MEI",
    version: "5.0",
    customization: "All",
  },
  "MEI 4.0.1": {
    apiIds: ["mei401", "c-mei-all"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.1",
    customization: "All",
  },
  "MEI 4.0.1 All anyStart": {
    apiIds: ["mei401-any", "c-mei-all-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.1",
    customization: "All anyStart",
  },
  "MEI 4.0.1 CMN": {
    apiIds: ["mei401-cmn", "c-mei-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.1",
    customization: "CMN",
  },
  "MEI 4.0.1 Mensural": {
    apiIds: ["mei401-mensural", "c-mei-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.1",
    customization: "Mensural",
  },
  "MEI 4.0.1 Neumes": {
    apiIds: ["mei401-neumes", "c-mei-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.1",
    customization: "Neumes",
  },
  "MEI 4.0.0": {
    apiIds: ["mei400"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.0",
    customization: "All",
  },
  "MEI 4.0.0 All anyStart": {
    apiIds: ["mei400-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.0",
    customization: "All anyStart",
  },
  "MEI 4.0.0 CMN": {
    apiIds: ["mei400-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.0",
    customization: "CMN",
  },
  "MEI 4.0.0 Mensural": {
    apiIds: ["mei400-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.0",
    customization: "Mensural",
  },
  "MEI 4.0.0 Neumes": {
    apiIds: ["mei400-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.0",
    customization: "Neumes",
  },
  "MEI 3.0.0": {
    apiIds: ["mei300"],
    hash: "",
    link: "",
    format: "MEI",
    version: "3.0.0",
    customization: "All",
  },
  "MEI 3.0.0 All anyStart": {
    apiIds: ["mei300-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "3.0.0",
    customization: "All anyStart",
  },
  "MEI 3.0.0 CMN": {
    apiIds: ["mei300-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "3.0.0",
    customization: "CMN",
  },
  "MEI 3.0.0 Mensural": {
    apiIds: ["mei300-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "3.0.0",
    customization: "Mensural",
  },
  "MEI 3.0.0 Neumes": {
    apiIds: ["mei300-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "3.0.0",
    customization: "Neumes",
  },
  "MEI 2.1.1": {
    apiIds: ["mei211"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.1.1",
    customization: "All",
  },
  "MEI 2.1.1 All anyStart": {
    apiIds: ["mei211-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.1.1",
    customization: "All anyStart",
  },
  "MEI 2.1.1 CMN": {
    apiIds: ["mei211-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.1.1",
    customization: "CMN",
  },
  "MEI 2.1.1 Mensural": {
    apiIds: ["mei211-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "4.0.0",
    customization: "Mensural",
  },
  "MEI 2.1.1 Neumes": {
    apiIds: ["mei211-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.1.1",
    customization: "Neumes",
  },
  "MEI 2.0.0": {
    apiIds: ["mei200"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.0.0",
    customization: "All",
  },
  "MEI 2.0.0 All anyStart": {
    apiIds: ["mei200-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.0.0",
    customization: "All anyStart",
  },
  "MEI 2.0.0 CMN": {
    apiIds: ["mei200-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.0.0",
    customization: "CMN",
  },
  "MEI 2.0.0 Mensural": {
    apiIds: ["mei200-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.0.0",
    customization: "Mensural",
  },
  "MEI 2.0.0 Neumes": {
    apiIds: ["mei200-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "2.0.0",
    customization: "Neumes",
  },
  "MEI 1.0.0": {
    apiIds: ["mei100"],
    hash: "",
    link: "",
    format: "MEI",
    version: "1.0.0",
    customization: "All",
  },
  "MEI 1.0.0 All anyStart": {
    apiIds: ["mei100-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "1.0.0",
    customization: "All anyStart",
  },
  "MEI 1.0.0 CMN": {
    apiIds: ["mei100-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "1.0.0",
    customization: "CMN",
  },
  "MEI 1.0.0 Mensural": {
    apiIds: ["mei100-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "1.0.0",
    customization: "Mensural",
  },
  "MEI 1.0.0 Neumes": {
    apiIds: ["mei100-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "1.0.0",
    customization: "Neumes",
  },
  "MEI dev": {
    apiIds: ["meidev"],
    hash: "",
    link: "",
    format: "MEI",
    version: "dev",
    customization: "All",
  },
  "MEI dev All anyStart": {
    apiIds: ["meidev-any"],
    hash: "",
    link: "",
    format: "MEI",
    version: "dev",
    customization: "All anyStart",
  },
  "MEI dev CMN": {
    apiIds: ["meidev-cmn"],
    hash: "",
    link: "",
    format: "MEI",
    version: "dev",
    customization: "CMN",
  },
  "MEI dev Mensural": {
    apiIds: ["meidev-mensural"],
    hash: "",
    link: "",
    format: "MEI",
    version: "dev",
    customization: "Mensural",
  },
  "MEI dev Neumes": {
    apiIds: ["meidev-neumes"],
    hash: "",
    link: "",
    format: "MEI",
    version: "dev",
    customization: "Neumes",
  },
  "MEI dev basic": {
    apiIds: ["meidev-basic"],
    hash: "",
    link: "",
    format: "MEI",
    version: "dev",
    customization: "basic",
  },
};
